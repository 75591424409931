import { LoaChangeEvent } from 'src/app/modules/memos/components/upload-memos/loa-customizer/loa-customizer.component';
import { Widget } from 'src/app/modules/memos/components/upload-memos/pdf-signature-customizer/pdf-signature-customizer.model';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { LoaLevel } from './memo.model';
import { Params } from '@angular/router';

export class SaveMemo {
  static readonly type = '[Memo] SaveMemo';
  constructor(public payload: any) {}
}

export class SetInitMemo {
  static readonly type = '[Memo] Set initial memo';
  constructor(public payload: any) {}
}

export class SavePristineMemo {
  static readonly type = '[Memo] Save Pristine memo';
  constructor(public value: any, public name: string) {}
}

export class SaveItemMemo {
  static readonly type = '[Memo] SaveItemMemo';
  constructor(public value: any, public name: string) {}
}

export class ChangeLoa {
  constructor(public loaId?: number | null, public params?: Params) {}
}

export class MemoCarbonAccountingUpdateMemo {
  static readonly type = '[MemoCarbonAccounting] Update Memo';
  constructor(public value: any, public name: string) {}
}

export class UpdateMemoDetail {
  constructor(public key: string, public value: any) {}
}

export class DdocSettingsUpdateMemo extends UpdateMemoDetail {
  static readonly type = '[DdocSettings] Update memo';
}

export class DropdownGetApiUpdateDept extends UpdateMemoDetail {
  static readonly type = '[DropdownGetApi] Update department';

  constructor(public override value: any) {
    super('department', value);
  }
}

export class DropdownGetApiUpdateMemo extends UpdateMemoDetail {
  static readonly type = '[DropdownGetApi] Update memo';
}

export class WidgetPdfHandlerUpdateMemo extends UpdateMemoDetail {
  static readonly type = '[MemoWidgetPdfHandler] Update memo';
}

export class MemoUploadAttachmentUpdateMemo extends UpdateMemoDetail {
  static readonly type = '[MemoUploadAttachment] Upload memo';
}

export class CreateMemo {
  static readonly type = '[Memo] CreateMemo';
  constructor(public payload: any) {}
}
export class UpdateMemo {
  static readonly type = '[Memo] UpdateMemo';
  constructor(public id: number, public payload: any) {}
}

export class AddLoaLevel {
  constructor(public name: string | null) {}
}

export class MemoCustomLoaAddLoaLevel extends AddLoaLevel {
  static readonly type = '[Memo Custom Loa] AddLoaLevel';
}

export class UploadMemoAddLoaLevel extends AddLoaLevel {
  static readonly type = '[Upload Memo] AddLoaLevel';
}

export class RemoveLoaLevel {
  static readonly type = '[Memo Customizer] RemoveLoaLevel';
  constructor(public index?: number) {}
}

export class UploadMemoSetCustomLoaLevels {
  static readonly type = '[Upload Memo] SetCustomLoaLevels';
  constructor(
    public loaLevels: Exclude<
      MemoDetail['loa_group'],
      null
    >['levels'],
  ) {}
}

export class SetLoa {
  constructor(public loaGroup: MemoDetail['loa_group'] | null) {}
}

export class MemoCustomLoaSetLoa extends SetLoa {
  static readonly type = '[MemoCustomLoa] Set LoaGroup';
}

export class MemoCustomLoaChangeLoa extends ChangeLoa {
  static readonly type = '[MemoCustomLoa] Change LoaGroup';
}

export class UploadMemoSetLoa extends SetLoa {
  static readonly type = '[Upload Memo] SetLoaGroup';
}

export class UpdateLoaLevel {
  static readonly type = '[Memo Customizer] UpdateLoaLevel';
  constructor(
    public index: number,
    public updatedLoaLevel: LoaLevel,
    public event?: LoaChangeEvent,
  ) {}
}

export class ResetLoa {
  static readonly type = '[Memo Customizer] ResetLOA';
  constructor() {}
}

export class ResetState {
  static readonly type = '[Memo] ResetState';
  constructor() {}
}
export class UploadAttachment {
  static readonly type = '[Memo] UploadAttachment';
  constructor(public id: number) {}
}

export class UploadEmissionEvidenceFile {
  static readonly type = '[Memo] UploadEmissionEvidenceFile';
  constructor(public purchaseId: number) {}
}

export class UploadSTCAttachment {
  static readonly type = '[Memo] UploadSTCAttachment';
  constructor(public items: any) {}
}

export class UploadPDFMemo {
  static readonly type = '[Memo] UploadPDFMemo';
  constructor(public id: number) {}
}

export class UploadMemoUploadPDF {
  static readonly type = '[Memo] UploadMemoUploadPDF';
  constructor(public uploadId: number, public memoId: number) {}
}

export class UploadMemoUploadMultiPDF {
  static readonly type = '[Memo] uploadMemoUploadMultiPDF';
  constructor(public uploadId: number) {}
}

export class ContractUploadPDF {
  static readonly type = '[Memo] ContractUploadPDF';
  constructor(public contractId: number, public memoId: number) {}
}

export class ContractUploadMultiPDF {
  static readonly type = '[Memo] ContractUploadMultiPDF';
  constructor(public contractId: number) {}
}

export class UploadEFormFile {
  static readonly type = '[Memo State] Upload e-form file';
  constructor(public memoId: number) {}
}

export class ErrorNotification {
  static readonly type = '[Memo] SetError Notification';
  constructor(public error: any) {}
}
export class PreviewMemo {
  static readonly type = '[Memo] Preview Memo';
  constructor(public payload: any) {}
}

export class ShowMemoPasswordPopup {
  static readonly type = '[Memo] ShowMemoPasswordPopup';
  constructor(
    public isCreator: boolean,
    public memoId: number | null,
  ) {}
}

export class CloseMemoPasswordPopup {
  static readonly type = '[Memo] CloseMemoPasswordPopup';
  constructor(public password: string | null) {}
}

export class ClearMemoPasswordPopup {
  static readonly type = '[Memo] ClearMemoPasswordPopup';
  constructor() {}
}

export class SetWidgets {
  static readonly type = '[WidgetPdfHandler] SetWidget';
  constructor(public widget: Widget[]) {}
}

export class UpdateWidget {
  static readonly type = '[WidgetPdfHandler] Update widget';
  constructor(public index: number, public widget: Widget) {}
}

export class PrepareEFormValidation {
  static readonly type = '[CreateMemo] Prepare e-form validation';
  constructor() {}
}
