import { Injectable } from '@angular/core';
import {
  Action,
  createModelSelector,
  createPropertySelectors,
  State,
  StateContext,
  Store,
} from '@ngxs/store';
import { finalize } from 'rxjs';
import { MemoService } from 'src/app/modules/memos/service/memo.service';
import {
  Approve,
  CancelDecisionPopup,
  ClearSelectMemo,
  ClearState,
  ClosePreviewData,
  EmptyContent,
  MultiApprove,
  MultiReject,
  MultiTerminate,
  NextApprovalPopup,
  Reject,
  InitialMemoDetail,
  SaveComment,
  SaveConfirm,
  SaveOTP,
  SaveReason,
  SaveSignature,
  SaveStamp,
  ShowDecisionPopup,
  ShowMemoDetail,
  ShowPreviewData,
  Terminate,
  ToggleMenu,
  UpdateMultiMemos,
} from './approval-request.actions';
import {
  ApprovalRequestList,
  ApprovalRequestStateModel,
  DecitionPopup,
  MemoAction,
  NextApprovalPopupState,
  PreviewData,
} from './approval-request.models';
import { SpinnerService } from '@core/services/spinner.service';
import { ErrorNotification } from '../memo/memo.actions';
import { MemoDetail } from 'src/app/modules/memos/model/memo.model';
import { NotificationService } from '@shared/service/notification.service';
import { REQUIRE_COMMENT_MEMO_TYPES } from '@shared/utils/memos.constant';
import { append, patch, updateItem } from '@ngxs/store/operators';

const defaultData = {
  memo: null,
  viaLink: false,
  selectedMemoIds: [],
  showingMenu: true,
  emptyContent: true,
  showDecisionPopup: null,
  passedDecisionPopups: [],
  nextApprovalPopup: null,
  actionPayload: {
    action: null,
    comment: null,
    reason: null,
    ref: null,
    otp: null,
    signature: null,
    stamp: [],
  },
  previewData: {
    preview: false,
    previewDataList: [],
    previewData: null,
    header: '',
  },
  multiMemos: [],
  exceedMaximumApprove: false,
  multiApprovalTaskId: null,
  selectedType: null,
};

@State<ApprovalRequestStateModel>({
  name: 'approvalRequest',
  defaults: defaultData,
})
@Injectable({
  providedIn: 'root',
})
export class ApprovalRequestState {
  constructor(
    private memoService: MemoService,
    private spinnerService: SpinnerService,
    private store: Store,
    public notificationService: NotificationService,
  ) {}

  @Action(ShowMemoDetail)
  showMemoDetail(
    { setState, getState }: StateContext<ApprovalRequestStateModel>,
    payload: ShowMemoDetail,
  ) {
    this.spinnerService.show();
    let getMemoDetail = this.memoService.getMemoDetail(
      payload.memoId,
    );
    if (
      payload.viaLink &&
      payload?.options?.memo_type === 'contract'
    ) {
      getMemoDetail = this.memoService.getContractDetail(
        payload.memoId,
        payload?.options?.otp || '',
        payload?.options?.ref || '',
      );
    }
    getMemoDetail
      .pipe(
        finalize(() => {
          this.spinnerService.hide();
        }),
      )
      .subscribe(
        (res) => {
          const viaLink = payload.viaLink || false;
          this.store.dispatch(new InitialMemoDetail(res, viaLink));
        },
        (error: any) => {
          if (
            error.status === 403 &&
            ['REQT_INVALID_PASSWORD', 'INVALID_PASSWORD'].includes(
              error.error.detail,
            )
          ) {
            const state = getState();
            this.store.dispatch(new ClearState());
            this.store.dispatch(new EmptyContent(state.emptyContent));
          } else if (
            ['invalid_otp', 'missing_otp'].includes(error.error?.code)
          ) {
            const state = getState();
            this.store.dispatch(new ClearState());
            this.store.dispatch(new EmptyContent(state.emptyContent));
            if (payload?.options?.onVerifyError) {
              payload.options.onVerifyError(error);
            }
          } else {
            this.store.dispatch(new ErrorNotification(error));
          }
        },
      );
  }

  @Action(InitialMemoDetail)
  initialMemoDetail(
    { setState, getState }: StateContext<ApprovalRequestStateModel>,
    payload: InitialMemoDetail,
  ) {
    const state = getState();
    const viaLink = payload.viaLink || false;
    setState({
      ...state,
      memo: payload.detail,
      viaLink: viaLink,
      showDecisionPopup: null,
      passedDecisionPopups: [],
      nextApprovalPopup: null,
      emptyContent: false,
      actionPayload: {
        action: null,
        comment: null,
        reason: null,
        ref: null,
        otp: null,
        signature: null,
        stamp: [],
      },
    });
  }

  @Action(ClearSelectMemo)
  clearSelectMemo({
    patchState,
  }: StateContext<ApprovalRequestStateModel>) {
    patchState({
      memo: null,
    });
  }

  @Action(ToggleMenu)
  toggleMenu({
    patchState,
    getState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    patchState({
      showingMenu: !state.showingMenu,
    });
  }

  @Action(ShowDecisionPopup)
  showDecisionPopup(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    payload?: { action: MemoAction },
  ) {
    const state = getState();
    const memo = state.memo;
    const multiMemos = state.multiMemos;
    if (!memo && !multiMemos.length) {
      return;
    }
    const memos = multiMemos.length > 0 ? multiMemos : [memo];

    const actionPayload = state.actionPayload;
    if (payload?.action) {
      actionPayload.action = payload.action;
    }
    const action = actionPayload.action;
    if (!action) {
      return;
    }

    const pass = (popup: DecitionPopup): boolean => {
      return state.passedDecisionPopups.includes(popup);
    };
    const checkCondition = (
      condition: (memo: MemoDetail | ApprovalRequestList) => boolean,
    ): boolean => {
      return memos.some((memo) => memo && condition(memo));
    };

    let showDecisionPopup: DecitionPopup | null = null;
    if (action == 'approve') {
      if (
        checkCondition(
          (memo): boolean =>
            memo.upload?.is_comment_required ||
            REQUIRE_COMMENT_MEMO_TYPES.includes(memo.memo_type) ||
            false,
        ) &&
        !pass('comment')
      ) {
        showDecisionPopup = 'comment';
      } else if (
        checkCondition(
          (memo): boolean => memo.current_level_signature_required,
        ) &&
        !pass('signature')
      ) {
        showDecisionPopup = 'signature';
      } else if (
        checkCondition((memo): boolean => memo.otp_enable || false) &&
        !pass('otp')
      ) {
        showDecisionPopup = 'otp';
      } else if (
        checkCondition(
          (memo): boolean =>
            !memo.current_level_signature_required &&
            !memo.otp_enable &&
            !memo.upload?.is_comment_required,
        ) &&
        !pass('confirm')
      ) {
        showDecisionPopup = 'confirm';
      }
    } else {
      if (!pass('reason')) {
        showDecisionPopup = 'reason';
      } else if (
        checkCondition((memo): boolean => memo.otp_enable || false) &&
        !pass('otp')
      ) {
        showDecisionPopup = 'otp';
      }
    }
    if (showDecisionPopup) {
      setState({
        ...state,
        showDecisionPopup,
        actionPayload,
      });
    } else {
      switch (action) {
        case 'approve':
          multiMemos.length
            ? this.store.dispatch(new MultiApprove())
            : this.store.dispatch(new Approve());
          break;
        case 'reject':
          multiMemos.length
            ? this.store.dispatch(new MultiReject())
            : this.store.dispatch(new Reject());
          break;
        case 'terminate':
          multiMemos.length
            ? this.store.dispatch(new MultiTerminate())
            : this.store.dispatch(new Terminate());
          break;
      }
    }
  }

  @Action(SaveComment)
  saveComment(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    { comment }: { comment: string },
  ) {
    const state = getState();
    const passedDecisionPopups = state.passedDecisionPopups;
    const actionPayload = state.actionPayload;

    passedDecisionPopups.push('comment');
    actionPayload.comment = comment;

    setState({
      ...state,
      passedDecisionPopups,
      actionPayload,
    });
    this.store.dispatch(new ShowDecisionPopup());
  }

  @Action(SaveOTP)
  saveOTP(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    { otp }: { otp: string },
  ) {
    const state = getState();
    const passedDecisionPopups = state.passedDecisionPopups;
    const actionPayload = state.actionPayload;

    passedDecisionPopups.push('otp');
    actionPayload.otp = otp;
    actionPayload.ref = this.store.selectSnapshot<string>(
      (s) => s.otp?.ref,
    );

    setState({
      ...state,
      passedDecisionPopups,
      actionPayload,
    });
    this.store.dispatch(new ShowDecisionPopup());
  }

  @Action(SaveReason)
  saveReason(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    { reason }: { reason: string },
  ) {
    const state = getState();
    const passedDecisionPopups = state.passedDecisionPopups;
    const actionPayload = state.actionPayload;

    passedDecisionPopups.push('reason');
    actionPayload.reason = reason;

    setState({
      ...state,
      passedDecisionPopups,
      actionPayload,
    });
    this.store.dispatch(new ShowDecisionPopup());
  }

  @Action(SaveSignature)
  saveSignature(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    { signature }: { signature: any },
  ) {
    const state = getState();
    const passedDecisionPopups = state.passedDecisionPopups;
    const actionPayload = state.actionPayload;

    passedDecisionPopups.push('signature');
    actionPayload.signature = signature;

    setState({
      ...state,
      passedDecisionPopups,
      actionPayload,
    });
    this.store.dispatch(new ShowDecisionPopup());
  }

  @Action(SaveStamp)
  saveStamp(
    { getState, setState }: StateContext<ApprovalRequestStateModel>,
    { key, file, imageUrl }: SaveStamp,
  ) {
    const stamp = getState().actionPayload.stamp;
    const newStamp = { key, file, imageUrl };
    const idx = stamp.findIndex((item) => item.key === key);
    if (idx === -1) {
      setState(
        patch({
          actionPayload: patch({
            stamp: append([newStamp]),
          }),
        }),
      );
    } else {
      setState(
        patch({
          actionPayload: patch({
            stamp: updateItem(idx, newStamp),
          }),
        }),
      );
    }
  }

  @Action(SaveConfirm)
  saveConfirm({
    getState,
    setState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    const passedDecisionPopups = state.passedDecisionPopups;
    passedDecisionPopups.push('confirm');
    setState({
      ...state,
      passedDecisionPopups,
    });
    this.store.dispatch(new ShowDecisionPopup());
  }

  @Action(CancelDecisionPopup)
  cancelDecisionPopup({
    getState,
    setState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    setState({
      ...state,
      showDecisionPopup: null,
      passedDecisionPopups: [],
      actionPayload: defaultData.actionPayload,
    });
  }

  @Action(ClearState)
  clearState({ setState }: StateContext<ApprovalRequestStateModel>) {
    setState(defaultData);
  }

  @Action(Approve)
  approve({ getState }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const fd = new FormData();
    if (actionPayload.comment) {
      if (
        REQUIRE_COMMENT_MEMO_TYPES.includes(
          (state.memo as MemoDetail).memo_type,
        )
      ) {
        fd.set('approval_comment', actionPayload.comment);
      } else {
        fd.set('comment', actionPayload.comment);
      }
    }
    if (actionPayload.otp) {
      fd.append('otp', actionPayload.otp);
    }
    if (actionPayload.ref) {
      fd.append('ref', actionPayload.ref);
    }
    if (actionPayload.stamp?.length > 0) {
      actionPayload.stamp.forEach(({ key, file }) => {
        fd.append(key, file);
      });
    }
    if (actionPayload.signature) {
      fd.append('one_time_signature', actionPayload.signature);
    }
    this.memoService
      .approveMemo((state.memo as MemoDetail).id, fd)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res) => {
          this.handleActionSuccess(state, res);
        },
        error: (error) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(Reject)
  reject({ getState }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const data = {
      reason: actionPayload.reason,
      otp: actionPayload.otp,
      ref: actionPayload.ref,
    };
    this.memoService
      .rejectMemo((state.memo as MemoDetail).id, data)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: () => {
          this.handleActionSuccess(state);
        },
        error: (error) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(Terminate)
  terminate({ getState }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const data = {
      reason: actionPayload.reason,
      otp: actionPayload.otp,
      ref: actionPayload.ref,
    };
    this.memoService
      .terminateMemo((state.memo as MemoDetail).id, data)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res) => {
          this.handleActionSuccess(state, res);
        },
        error: (error) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(MultiApprove)
  multiApprove({
    getState,
    patchState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const fd = new FormData();
    state.multiMemos.forEach((memo) =>
      fd.append('memos', memo.id.toString()),
    );
    if (actionPayload.comment) {
      fd.set('comment', actionPayload.comment);
    }
    if (actionPayload.otp) {
      fd.set('otp', actionPayload.otp);
    }
    if (actionPayload.ref) {
      fd.set('ref', actionPayload.ref);
    }
    if (actionPayload.signature) {
      fd.set('one_time_signature', actionPayload.signature);
    }
    this.memoService
      .approveMultiMemos(fd)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: { task_id: string }) => {
          patchState({
            multiApprovalTaskId: res.task_id,
          });
        },
        error: (error: any) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(MultiReject)
  multiReject({
    getState,
    patchState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const data = {
      memos: state.multiMemos.map((memo) => memo.id),
      reason: actionPayload.reason,
      otp: actionPayload.otp,
      ref: actionPayload.ref,
    };
    this.memoService
      .rejectMultiMemos(data)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: { task_id: string }) => {
          patchState({
            multiApprovalTaskId: res.task_id,
          });
        },
        error: (error: any) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(MultiTerminate)
  multiTerminate({
    getState,
    patchState,
  }: StateContext<ApprovalRequestStateModel>) {
    const state = getState();
    this.spinnerService.show();
    const actionPayload = state.actionPayload;
    const data = {
      memos: state.multiMemos.map((memo) => memo.id),
      reason: actionPayload.reason,
      otp: actionPayload.otp,
      ref: actionPayload.ref,
    };
    this.memoService
      .terminateMultiMemos(data)
      .pipe(finalize(() => this.spinnerService.hide()))
      .subscribe({
        next: (res: { task_id: string }) => {
          patchState({
            multiApprovalTaskId: res.task_id,
          });
        },
        error: (error: any) => {
          this.store.dispatch(new ErrorNotification(error));
          this.store.dispatch(new CancelDecisionPopup());
        },
      });
  }

  @Action(NextApprovalPopup)
  nextApprovalPopup(
    { patchState }: StateContext<ApprovalRequestStateModel>,
    { state }: { state: NextApprovalPopupState | null },
  ) {
    patchState({
      nextApprovalPopup: state,
      multiApprovalTaskId: null,
      multiMemos: [],
      exceedMaximumApprove: false,
      selectedType: null,
    });
  }

  @Action(ShowPreviewData)
  showPreviewData(
    { patchState }: StateContext<ApprovalRequestStateModel>,
    { payload }: { payload: PreviewData },
  ) {
    patchState({
      previewData: payload,
    });
  }

  @Action(ClosePreviewData)
  closePreviewData({
    patchState,
  }: StateContext<ApprovalRequestStateModel>) {
    patchState({
      previewData: defaultData.previewData,
    });
  }

  @Action(EmptyContent)
  emptyContent(
    { patchState }: StateContext<ApprovalRequestStateModel>,
    { emptyContent }: { emptyContent?: boolean },
  ) {
    patchState({
      emptyContent: emptyContent,
    });
  }

  @Action(UpdateMultiMemos)
  updateMultiMemos(
    { patchState }: StateContext<ApprovalRequestStateModel>,
    { payload }: { payload: ApprovalRequestList[] },
  ) {
    const MAXIMUM_MULTI_APPROVE = 10;
    const exceedMaximumApprove =
      payload.length >= MAXIMUM_MULTI_APPROVE;
    patchState({
      multiMemos: payload,
      exceedMaximumApprove: exceedMaximumApprove,
      selectedType: this.checkSelectedMemoType(payload),
    });
  }

  handleActionSuccess(
    state: ApprovalRequestStateModel,
    memoRes?: MemoDetail,
  ) {
    if (state.viaLink) {
      if (memoRes) {
        this.store.dispatch(
          new InitialMemoDetail(memoRes, state.viaLink),
        );
      } else {
        this.store.dispatch(
          new ShowMemoDetail((state.memo as MemoDetail).id, true),
        );
      }
    } else {
      this.notificationService.updateNotificationCount();
      this.store.dispatch(new NextApprovalPopup('show'));
    }
  }

  checkSelectedMemoType(
    memos: ApprovalRequestList[],
  ): 'memo' | 'contract' | null {
    if (!memos?.length) {
      return null;
    }
    const contractList = memos.filter(
      (m) => m.memo_type === 'contract',
    );
    if (contractList.length === memos.length) {
      return 'contract';
    }
    if (contractList.length === 0) {
      return 'memo';
    }
    throw new Error('Not allow to selected both memo and contract');
  }
}

export class ApprovalRequestSelectors {
  static getSlices =
    createPropertySelectors<ApprovalRequestStateModel>(
      ApprovalRequestState,
    );

  static getBasicSlices = createModelSelector({
    showingMenu: ApprovalRequestSelectors.getSlices.showingMenu,
    nextApprovalPopup:
      ApprovalRequestSelectors.getSlices.nextApprovalPopup,
    emptyContent: ApprovalRequestSelectors.getSlices.emptyContent,
  });
}
